@layer components {
  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply antialiased;
  }

  .h1 {
    @apply font-inter text-[40px] font-bold leading-[44px] tracking-[-0.04em];

    @screen md {
      @apply text-[48px] leading-[52px];
    }

    @screen lg {
      @apply text-[56px] leading-[60px] tracking-[-0.03em];
    }

    /* @screen xl {
      @apply text-[64px] leading-[68px];
    }

    @screen 2xl {
      @apply text-[72px] leading-[76px];
    } */
  }

  .h2 {
    @apply font-inter text-[32px] font-bold leading-[36px] tracking-[-0.03em];

    @screen md {
      @apply text-[39px] leading-[43px];
    }

    @screen lg {
      @apply text-[46px] leading-[50px];
    }

    /* @screen xl {
      @apply text-[53px] leading-[57px];
    }

    @screen 2xl {
      @apply text-[60px] leading-[64px];
    } */
  }

  .h3 {
    @apply font-inter text-[24px] font-semibold leading-[32px] tracking-[-0.03em];

    @screen md {
      @apply text-[30px] leading-[38px];
    }

    @screen lg {
      @apply text-[36px] leading-[44px];
    }

    /* @screen xl {
      @apply text-[42px] leading-[50px];
    }

    @screen 2xl {
      @apply text-[48px] leading-[56px];
    } */
  }

  .h4 {
    @apply font-inter text-[24px] font-medium leading-[32px] tracking-[-0.03em];

    @screen md {
      @apply text-[28px] leading-[36px];
    }

    @screen lg {
      @apply text-[32px] leading-[40px];
    }

    /* @screen xl {
      @apply text-[36px] leading-[44px];
    }

    @screen 2xl {
      @apply text-[40px] leading-[48px];
    } */
  }

  .h5 {
    @apply font-inter text-[18px] font-medium leading-[24px] tracking-[-0.03em];

    @screen md {
      @apply text-[20px] leading-[28px];
    }

    @screen lg {
      @apply text-[22px] leading-[30px];
    }

    /* @screen xl {
      @apply text-[24px] leading-[32px];
    }

    @screen 2xl {
      @apply text-[26px] leading-[34px];
    } */
  }

  .h6 {
    @apply font-inter text-[18px] font-medium leading-[24px] tracking-[-0.03em];

    @screen md {
      @apply text-[19px] leading-[25px];
    }

    @screen lg {
      @apply text-[20px] leading-[26px];
    }

    /* @screen xl {
      @apply text-[21px] leading-[27px];
    }

    @screen 2xl {
      @apply text-[22px] leading-[28px];
    } */
  }

  .body-banner {
    @apply font-inter text-[24px] font-medium leading-[29px];
  }

  .body-hero {
    @apply font-inter text-[24px] font-medium leading-[32px] tracking-[-0.03em];
  }

  /* .body-1 */
  .body-1 {
    @apply font-inter text-[18px] font-medium leading-[24px] tracking-[0.015em];
  }

  .body-2 {
    @apply font-inter text-[18px] font-normal leading-[24px] tracking-[0.015em];
  }

  .body-3 {
    @apply font-inter text-[16px] font-normal leading-[21px] tracking-[0.015em];
  }

  .body-4 {
    @apply font-inter text-[14px] font-medium leading-[20px] tracking-[0.015em];
  }

  .body-5 {
    @apply font-inter text-[12px] font-normal leading-[16px] tracking-[0.015em];
  }

  .btn-primary {
    @apply bg-blueberry-700 hover:text-blueberry-100 hover:bg-blueberry-800 focus:text-blueberry-100 focus:bg-blueberry-800 font-inter min-h-[64px] min-w-[184px] rounded-full text-[16px] font-normal leading-[64px] tracking-[0.015em] text-white;
  }

  .btn-secondary {
    @apply text-blueberry-700 hover:text-blueberry-800 hover:bg-blueberry-100 focus:text-blueberry-800 focus:bg-blueberry-100 font-inter min-h-[64px] min-w-[184px] rounded-full bg-white text-[16px] font-normal leading-[64px] tracking-[0.015em];
  }

  .content-margin {
    @apply mb-6 mt-0;
  }

  .content-h1 {
    @apply font-inter mb-6 mt-0 text-[24px] font-bold leading-[1.2] tracking-[-0.03em];
  }

  .content-h2 {
    @apply font-inter mb-6 mt-0 text-[22px] font-bold leading-[1.2] tracking-[-0.03em];
  }

  .content-h3 {
    @apply font-inter mb-6 mt-0 text-[20px] font-semibold leading-[1.2] tracking-[-0.03em];
  }

  .content-h4 {
    @apply font-inter mb-6 mt-0 text-[18px] font-medium leading-[1.2] tracking-[-0.03em];
  }

  .content-h5 {
    @apply font-inter mb-6 mt-0 text-[16px] font-medium leading-[1.2] tracking-[-0.03em];
  }

  .content-h6 {
    @apply font-inter mb-6 mt-0 text-[14px] font-medium leading-[1.2] tracking-[-0.03em];
  }

  .content-body {
    @apply font-inter mb-6 mt-0 text-[16px] font-normal leading-[1.6];
  }

  .content-publish-info {
    @apply font-inter text-[12px] font-normal leading-[16px] tracking-[0.015em];
  }

  .content-disclosure {
    @apply font-inter text-[12px] font-normal leading-[16px] tracking-[0.015em];
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
}
